<template>
    <b-card>
        <template v-slot:header>
            <h5>Kelola Selisih Pembelian</h5>
        </template>
        <!-- <pre>
            {{ formMapingSelisih }}
        </pre> -->
        <b-table bordered hover show-empty :busy="isLoading" :items="listSelisih" :fields="fields" responsive class="mb-3">
            <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading data...</strong>
                </div>
            </template>
            <template v-slot:head(action)="row">
                <div class="text-right">
                    Aksi
                    <!-- <b-form-radio-group @change="(e) => handleChangeRadio(e, row.item)" :options="optionHeaderRadio"
                        value-field="item" text-field="name" disabled-field="notEnabled"></b-form-radio-group> -->
                </div>
            </template>
            <template v-slot:cell(nama_barang)="row">
                <div>
                    <div>
                        <span>Nomor : {{ row.item.nomer_barang }}</span>
                    </div>
                    <span>Nama : {{ row.item.barang_tipe.tipe || "-" }}</span>
                </div>
            </template>
            <template v-slot:cell(total_item)="row">
                <div>
                    <div>
                        <span>Jumlah Beli : {{ row.item.jumlah }} {{ row.item.satuan }}</span>
                    </div>
                    <span>Jumlah Selisih : {{ row.item.selisih || "-" }} {{ row.item.satuan }}</span>
                </div>
            </template>

            <template v-slot:cell(action)="row">
                <div class="text-right">
                    <!-- v-model="selected" -->
                    <b-form-radio-group @change="(e) => handleChangeRadio(e, row.item)" :options="optionRadio"
                        value-field="item" text-field="name" disabled-field="notEnabled"></b-form-radio-group>
                </div>
            </template>
            <template v-slot:cell(total_dana)="row">
                <div class="d-flex justify-content-between">
                    <div>Rp.</div>
                    <span>{{ $helper.rupiah(row.item.total_harga) || "0" }}</span>
                </div>
            </template>
        </b-table>
        <div class="d-flex justify-content-end">
            <b-button @click="handleButtonCancel" type="button" variant="secondary" class="mr-2">Batal</b-button>
            <b-button @click="handleButtonSubmit" variant="pgiBtn">Submit</b-button>
        </div>

        <!-- MODAL CONFIRM SUBMIT -->
        <b-modal id="m-confirm-submit" size="md" centered title="Proses Barang Selisih" no-close-on-esc hide-footer>
            <p>Anda yakin akan memproses barang selisih?</p>
            <!-- <div>
                <div class="d-flex justify-content-between">
                    <span>Refund</span>
                    <span>{{ countRefund }}</span>
                </div>
                <div class="d-flex justify-content-between">
                    <span>Kirim Ulang</span>
                    <span>{{ countKirimUlang }}</span>
                </div>
            </div> -->
            <div class="d-flex justify-content-end pt-2">
                <b-button @click="handleProsesSelisih" variant="pgiBtn">Proses</b-button>
            </div>
        </b-modal>
        <!-- MODAL CONFIRM SUBMIT -->
    </b-card>
</template>
<script>
import axiosClient from '../../../config/api'

export default {
    data() {
        return {
            isLoading: false,
            fields: [
                { key: "nama_barang", label: "Barang" },
                { key: "total_item", label: "Barang Selisih" },
                { key: "total_dana", label: "Dana Selisih" },
                { key: "action", label: "Aksi" },
            ],
            listSelisih: [],
            countRefund: null,
            countKirimUlang: null,
            optionRadio: [
                { item: '2', name: 'Refund' },
                { item: '3', name: 'Kirim Ulang' }
            ],
            optionHeaderRadio: [
                { item: '2', name: 'Refund' },
                { item: '3', name: 'Kirim Ulang' }
            ],
            formMapingSelisih: []
        }
    },
    methods: {
        async getDetailSelisih() {
            const id = this.$route.params.id
            try {
                this.isLoading = true
                const r = await axiosClient({
                    method: 'GET',
                    url: 'transaksi/refund/refund/' + id
                })

                const barang_refund = r.data.data.barang_refund || []
                this.listSelisih = barang_refund
            } catch (error) {
                console.log(error.responsive.message)
            } finally {
                this.isLoading = false
            }
        },
        handleButtonCancel() {
            console.log("CANCEL")
            this.$router.go(-1)
        },
        handleButtonSubmit() {
            if (this.listSelisih.length == this.formMapingSelisih.length) {
                const xcountRefund = this.formMapingSelisih.filter(i => i.flag == 2)
                const xcountKirimUlang = this.formMapingSelisih.filter(i => i.flag == 3)
                this.countRefund = xcountRefund.length
                this.countKirimUlang = xcountKirimUlang.length
                this.$bvModal.show('m-confirm-submit')
            } else {
                this.$helper.toastErr(this, "Anda belum mengelola semua selisih, Refund atau Kirim Ulang")
            }
        },
        handleChangeRadio(e, value) {
            const newlistSelisih = this.listSelisih.map(item => {
                if (item.id === value.id) {
                    item.flag = e
                    return item
                } else {
                    return item
                }
            })
            this.listSelisih = newlistSelisih
            const newDataSelected = newlistSelisih.filter(i => i.flag != 1)
            this.formMapingSelisih = newDataSelected.map(i => {
                return {
                    id_refund: i.id,
                    flag: i.flag
                }
            })
        },
        async handleProsesSelisih() {
            const form = this.formMapingSelisih
            try {
                this.isLoading = true
                const response = axiosClient({
                    url: 'transaksi/refund/update-refund',
                    method: 'POST',
                    data: form
                })
                this.$helper.toastSucc(this, "Sukses mengelola selisih")
                this.$router.push("/selisih-barang/daftar-selisih")
            } catch (error) {
                this.$helper.toastErr(this, error.response.data.message)
            }
            finally {
                this.isLoading = false
            }
        }
    },
    mounted() {
        this.getDetailSelisih()
    }
}
</script>